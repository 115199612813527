import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

// import { Stream } from "pages/Stream";
// import { StreamList } from "pages/StreamList";

import { MainPage } from "pages/MainPage";

export default function Routes(props) {

  return (
    <div className="Main-container">

      <div style={{ position: "relative" }} className="positionInner">
        <Switch>
          <Redirect exact from="/" to="/channels" />
          <Route
            path="/channels"
            render={() =>
              <MainPage
                navigation={props.navigation}
                history={props.history}
                location={props.location}
                auth={props.auth}
              />
            }
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}
