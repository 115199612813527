import React, { useEffect, useState, useContext } from "react";
import { Text } from "stories";
import "./ClassCard.css";

import moment from "moment";

const ONLINE_FLAG = "ONLINE";
const DISABLED_FLAG = "DISABLED";

export default function ClassCard(props) {
  //console.log("PROPS: ",props);

  const selectCourse = () => {
    if (props.info.status === ONLINE_FLAG && props.auth === "authenticated") {
      props.viewStream(props.info);
    }
  };

  let classes =
    "fullCard " +
    (props.info.status === ONLINE_FLAG ? "" : "channelUnavailable");
  let headerClasses =
    "imgHolder " +
    (props.info.status === ONLINE_FLAG || props.info.status === DISABLED_FLAG
      ? ""
      : "extraHeightHeader");

  const classInfo = props.info.classInfo;
  let classTimes = classInfo.times.split("|");

  const getNextClassTime = (t) => {
    if (!t) {
      return "Class time unknown";
    } else {
      let s_date = new Date(t.start * 1000);
      let e_date = new Date(t.end * 1000);

      return (
        <div>
          <div>{moment(s_date).format("dddd, MMMM D")}</div>
          <div>
            {moment(s_date).format("h:mma") +
              " - " +
              moment(e_date).format("h:mma")}
          </div>
        </div>
      );
    }
  };

  return (
    <div key={props.info.id} className={classes} onClick={selectCourse}>
      <div>
        {props.info.status === ONLINE_FLAG && props.auth === "authenticated" ? (
          <div className="watchNow">WATCH NOW</div>
        ) : null}
        {props.info.status === ONLINE_FLAG && props.auth !== "authenticated" ? (
          <div className="watchNow">SIGN IN TO WATCH NOW</div>
        ) : null}
        {props.info.status === DISABLED_FLAG ? (
          <div className="disabledNow">DISABLED</div>
        ) : null}
        <div className={headerClasses}>{classInfo.course_number}</div>
        <div className="infoHolder">
          <div className="info_base info_title">{classInfo.course_name}</div>
          <div className="info_base info_time">
            {getNextClassTime(props.info.nct)}
          </div>
          <div className="info_base info_prof">{classInfo.prof}</div>
        </div>
      </div>
    </div>
  );
}
