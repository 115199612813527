import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios"

import CloseIcon from '@material-ui/icons/Close';

import "./Stream.css"

export default function Stream(props) {

  const [playUrl,setUrl] = useState("");
  const [ended, setEnded] = useState(false);
  const [classInfo, setClassInfo] = useState({})

  useEffect(() => {
    if( props.stream && props.stream.classInfo ) {

      console.log("STREAM: ",props)
      setClassInfo(props.stream.classInfo);
      getToken(props.stream)
    }

  },[props.stream])

  useEffect(() => {

    const script = document.createElement('script');
    if( playUrl !== null ) {

      script.src = 'https://player.live-video.net/1.0.0/amazon-ivs-player.min.js';
      script.async = false;

      document.body.appendChild(script);

      script.onload = () => {
          // eslint-disable-next-line no-undef
          if (IVSPlayer.isPlayerSupported) {
              // eslint-disable-next-line no-undef
              const player = IVSPlayer.create();
              player.attachHTMLVideoElement(document.getElementById('video-player'));

              try {
                const PlayerState = window.IVSPlayer.PlayerState;

                // Attach event listeners
                player.addEventListener(PlayerState.PLAYING, function () {
                  console.log(" ***************** PLAYING ***************** ");
                });
                player.addEventListener(PlayerState.ENDED, function () {
                  console.log(" ***************** ENDED ***************** ");
                  setEnded(true);
                });
                player.addEventListener(PlayerState.READY, function () {
                  console.log(" ***************** READY ******************* ");
                });
              } catch(e) {
                console.log("Failed attaching listeners: ",e);
              }

              player.load(playUrl);
              player.play();
          }
      }


    }

    return () => {
      try {
        document.body.removeChild(script);
      } catch(e) {}

    }


  },[playUrl])

  const getToken = async (channel) => {
    let resp = await axios.get("https://nl0cwt8hhg.execute-api.us-west-2.amazonaws.com/prod/sign?id="+channel.id);
    setUrl(channel.playbackUrl+"?token="+resp.data.token)
  }

  return (
    <div>
      <div className="videoHolder" id="fullVideo">
        <div className="videoExit" onClick={props.endStream}>
          <CloseIcon iconStyle={{height: "30px",width: "30px"}} />
        </div>
        <div className="playerWrapper" id="player-wrapper">
          <div className="videoPlayerHolder">
            <video id="video-player" className="videoPlayer" playsInline controls="controls"></video>
            <div className="videoSidePanel">
              <div className="videoSidePanelHeader">{classInfo.course_number} - {classInfo.course_name}</div>
              <div className="videoSidePanelContent">
                {
                  ended ? (
                    <div className="endedTag">
                      Stream has ended
                    </div>
                  ) : null
                }
                { classInfo.Description ? classInfo.Description : "No description available"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
