import React, { useEffect, useState, useContext } from "react";
import "./SearchBar.css";

import SearchBar from "material-ui-search-bar";

const ONLINE_FLAG = "ONLINE";

export default function SearchBarX(props) {

  const [value, setValue] = useState("");

  const searchForCourse = (x) => {
    props.searchForTerm(x);
  }

  return (
    <div>
      <SearchBar
          value={value}
          onChange={(newValue) => setValue(newValue)}
          onCancelSearch={() => {setValue("");searchForCourse("")}}
          style={{
            border: "2px solid black",
            boxShadow: "none",
            height: "30px",
            borderRadius: "0px"
          }}
          onRequestSearch={() => searchForCourse(value)}
        />
    </div>
  )

}
