import React, { useState, useEffect, useContext } from "react";
import "./MainPage.css"

import {StreamList} from "components/StreamList";
import {Stream} from "components/Stream";
import {Login} from 'components/Login';

import useScript from "hooks/useScript";
import useCssLink from "hooks/useCssLink";

export default function MainPage(props) {
  const [stream, setStream] = useState({})

  useScript("https://www.google-analytics.com/analytics.js","g-analytics-script");
  useScript("https://www.googletagmanager.com/gtag/js?id=G-TEHJR60KD9&amp;l=dataLayer&amp;cx=c","g-tagmanager-script");
  useScript("https://s3-us-west-2.amazonaws.com/syncshowcase.asu.edu/google-analytics.js","custom-analytics");
  useScript("https://s3-us-west-2.amazonaws.com/syncshowcase.asu.edu/google-tag.js","custom-tags");
  useScript("https://www.asu.edu/asuthemes/4.10/js/asu_header.min.js")
  useCssLink("https://www.asu.edu/asuthemes/4.10/css/asu_header.min.css");
  useCssLink("https://www.asu.edu/asuthemes/4.10/css/asu_header_media.min.css");

  useScript("https://s3-us-west-2.amazonaws.com/syncshowcase.asu.edu/asu_header.js");

  const playStream = (s) => {
    setStream({})
    setTimeout(() => {
      setStream(s);
    },500)
  }

  const endStream = () => {
    setStream({})
  }

  const destroyAuth = () =>  {
    localStorage.setItem("authed", "false");
    localStorage.clear();
    window.location.href = "https://webapp4.asu.edu/myasu/Signout";
  }

  return (
    <div>

      <div>
        <div class="header-wrapper"><header id="header" role="banner"></header></div>
      </div>

      <div className="">
        {
          stream && stream.id ? (
            <Stream stream={stream} endStream={endStream} />
          ) : null
        }

      </div>

      {
        process.env.NODE_ENV === "development" ? (
          <div onClick={destroyAuth}>
            Logout
          </div>
        ) : null
      }
      { props.auth !== "authenticated" && <Login />}
      <div className="">
        <StreamList playStream={playStream} auth={props.auth} />
      </div>
    </div>
  )

}
