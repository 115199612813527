import React, { useState, useEffect, useContext } from "react";
import {Text} from "stories";

import "./Hero.css";

export default function Hero(props) {

  return (
    <div className="heroHeader">
      <Text type="hero" text="ASU Sync for all." />
    </div>
  )
}
