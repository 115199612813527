import React, { useState, useEffect, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Routes from "./Routes";
import {Hero} from "components";

export default function MainApp(props) {

  const history = useHistory();
  const [expandNavBar, setExpandNavBar] = useState(false);

  const [statusBarToggle,setStatusBarToggle] = useState(false);
  const [universalStatus,setUniversalStatus] =  useState(null)

  const [navHistoryArray, setNavHistorArray] = useState([""]);

  useEffect(() => {
    window.onpopstate = (e) => {
      removeNavigation();
    };
  });

  const addNavigation = (string) => {
    string && setNavHistorArray([...navHistoryArray, string]);
  };

  const removeNavigation = (howMany = 1) => {
    if (navHistoryArray.length > 0 && navHistoryArray.length > howMany) {
      setNavHistorArray(
        navHistoryArray.splice(0, navHistoryArray.length - howMany)
      );
    }
  };

  const replaceNavigation = (arrayOfStrings) => {
    navHistoryArray.pop();
    if (Array.isArray(arrayOfStrings)) {
      arrayOfStrings.forEach((v) => {
        navHistoryArray.push(v);
      });
    } else {
      navHistoryArray.push(arrayOfStrings);
    }
  };

  const toggleShow = () => {
    setStatusBarToggle(!statusBarToggle);
  }

  const navigation = { addNavigation, removeNavigation, replaceNavigation };

  return (
    <div>
      <main className="main">
        <Hero />
        <Routes navigation={navigation} history={history}  hasStatusBar={props.maintenanceMode || universalStatus} auth={props.auth} />
      </main>
    </div>
  )

}
