import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  AUTH_ENDPOINT,
  CLIENT_ID,
  REDIRECT_URI,
  CLIENT_ID2,
  REDIRECT_URI2
} from "../constants";

const Weblogin = function (props) {
  const [authStatus, setAuthStatus] = useState("checking");

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    let tokens = JSON.parse(localStorage.getItem("usertokens"));

    if (
      tokens &&
      tokens.access_token &&
      tokens.expiration &&
      tokens.refresh_token
    ) {
      if (moment().unix() > tokens.expiration) {
        fetchOrRefreshTokens(tokens.refresh_token, "refresh");
      } else {
        setAuthStatus("authenticated");
      }
    } else {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("code")) {
        const code = urlParams.get("code");
        fetchOrRefreshTokens(code, "auth");
      } else {
        setAuthStatus("unauthenticated");
      }
    }
  };

  function navigateToLogin() {
    window.location.href =
      AUTH_ENDPOINT +
      "/login?client_id=" +
      CLIENT_ID2[process.env.NODE_ENV] +
      "&response_type=code&redirect_uri=" +
      REDIRECT_URI2[process.env.NODE_ENV];
  }

  function fetchOrRefreshTokens(value, type) {
    const params = new URLSearchParams();
    params.append("client_id", CLIENT_ID2[process.env.NODE_ENV]);
    params.append("redirect_uri", REDIRECT_URI2[process.env.NODE_ENV]);
    if (type === "refresh") {
      params.append("grant_type", "refresh_token");
      params.append("refresh_token", value);
    } else {
      params.append("grant_type", "authorization_code");
      params.append("code", value);
    }

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };

    axios
      .post(AUTH_ENDPOINT + "/oauth2/token", params, config)
      .then((result) => {
        result.data.expiration = moment().add(10, "minutes").unix();
        localStorage.setItem("usertokens", JSON.stringify(result.data));
        axios
          .get(AUTH_ENDPOINT + "/oauth2/userInfo", {
            headers: { Authorization: "Bearer " + result.data.access_token }
          })
          .then((userInfoResult) => {
            localStorage.setItem(
              "userinfo",
              JSON.stringify(userInfoResult.data)
            );
            setAuthStatus("authenticated");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        setAuthStatus("refreshFailed");
      });
  }

  if (authStatus === "checking") {
    return <div>loading</div>;
  } else if (authStatus === "authenticated") {
    props.login(authStatus)
    return <div>{props.children}</div>;
  } else if (authStatus === "refreshFailed") {
    props.login(authStatus)
    //return <div>failed to refresh</div>;
    return <div>{props.children}</div>
  } else {
    props.login(authStatus)
   // navigateToLogin();
   return <div>{props.children}</div>
  }
};

export default Weblogin;
