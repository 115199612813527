import React, { useState } from "react";
import Apple from "@material-ui/icons/Apple";
import Facebook from "@material-ui/icons/Facebook";
import Google from "../../assets/google.png";
import GoogleWhite from "../../assets/google-white.png";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  AUTH_ENDPOINT,
  CLIENT_ID2,
  REDIRECT_URI2,
  PROVIDERS
} from "../../constants";
import "./Login.css";

export default function Login(props) {
  const [gImage, setgImage] = useState(Google);

  function navigateToLogin(provider) {
    window.location.href =
      AUTH_ENDPOINT +
      PROVIDERS[provider] +
      CLIENT_ID2[process.env.NODE_ENV] +
      "&response_type=code&redirect_uri=" +
      REDIRECT_URI2[process.env.NODE_ENV];
  }

  const setIn = (e) => {
    setgImage(GoogleWhite);
  };
  const setOut = (e) => {
    setgImage(Google);
  };

  return (
    <div id="login">
      <div className="signin">
        <span class="signin-heading">
          {" "}
          <NotificationsIcon
            style={{
              width: "30px",
              height: "30px",
              marginTop: "17px",
              marginRight: "10px"
            }}
          />{" "}
          <h2>Sign in to stream all course content</h2>
        </span>
        <p style={{ paddingBottom: "10px" }}>
          ASU requires you to sign-in to watch courses. Sign-in with any of your
          accounts, we won't post content without your consent.
        </p>
        <button class="asu" onClick={() => navigateToLogin("asu")}>
          <span>Sign in with ASU SSO</span>
        </button>
        <button onClick={() => navigateToLogin("google")}>
          <img src={gImage} width={20} alt="Google Signin" />
          <span>Sign in with Google</span>
        </button>
        <button class="fb" onClick={() => navigateToLogin("facebook")}>
          <Facebook style={{ margin: "0 auto" }} />
          <span>Sign in with Facebook</span>
        </button>
        <button onClick={() => navigateToLogin("apple")}>
          <Apple />
          <span>Sign in with Apple</span>
        </button>
      </div>
    </div>
  );
}

/* 
<div>
        <span class="idpDescription-customizable">Sign In with your social account</span>
        
            
            
            
            <button name="appleSignUpButton" class="btn apple-button socialButton-customizable" onclick="window.location.href='/oauth2/authorize?identity_provider=SignInWithApple&amp;redirect_uri=http://localhost:3000&amp;response_type=CODE&amp;client_id=7mned1kctld70mjejgtv503dbq&amp;scope=email openid profile'">
                <span><svg class="social-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170" version="1.1">
    <g fill-rule="evenodd">
        <g fill="#fff">
            <path d="m150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zm-31.26-123.01c0 8.1021-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.3113 11.45-8.597 4.62-2.2516 8.99-3.4968 13.1-3.71 0.12 1.0831 0.17 2.1663 0.17 3.2409z"></path>
        </g>
    </g>
</svg></span>
                <span>Continue with Apple</span>
            </button>
        
            
            
            
            
        
            
            
            <button name="googleSignUpButton" onclick="window.location.href='/oauth2/authorize?identity_provider=Google&amp;redirect_uri=http://localhost:3000&amp;response_type=CODE&amp;client_id=7mned1kctld70mjejgtv503dbq&amp;scope=email openid profile'" class="btn google-button socialButton-customizable">
                <span><svg class="social-logo" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
    <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path>
    <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path>
    <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
    <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EA4335"></path>
</svg></span>
                <span>Continue with Google</span>
            </button>
            
        
            
            <button name="facebookSignUpButton" onclick="window.location.href='/oauth2/authorize?identity_provider=Facebook&amp;redirect_uri=http://localhost:3000&amp;response_type=CODE&amp;client_id=7mned1kctld70mjejgtv503dbq&amp;scope=email openid profile'" class="btn facebook-button socialButton-customizable">
                <span><svg class="social-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" color="#ffffff">
    <path fill="#ffffff" d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"></path>
</svg></span>
                <span>Continue with Facebook</span>
            </button>
            
            
        
        <br>
        <span class="legalText-customizable">We won't post to any of your accounts without asking first</span>
    </div>

    */
