import React, { useEffect, useState, useContext } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles,withStyles, createMuiTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import "./SortBy.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SortBy(props) {

  const classes = useStyles();
  const [sort, setSort] = React.useState('online');

  const handleChange = (event) => {
    setSort(event.target.value);
    props.sortByChange(event.target.value)
  };

  const StyledSelect = withStyles({
    select: {
      fontWeight: "bold",
      fontSize: "20px"
    }
  })(Select);

  const theme = createMuiTheme({
    overrides: {
      MuiInputLabel: {
        root: {
          color:'black',
          fontSize: "21px",
          fontWeight: "bold"
        },
      },
    }
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
        <StyledSelect
          labelId="simple-select-label"
          id="simple-select"
          value={sort}
          disableUnderline={true}
          onChange={handleChange}
          IconComponent={() => {
            return (
              <div>
                <ArrowDropUpIcon className="baseIcon topIcon" />
                <ArrowDropDownIcon className="baseIcon bottomIcon" />
              </div>
            )
          }}
        >
          <MenuItem value={"online"}>Online</MenuItem>
          <MenuItem value={"date"}>Date</MenuItem>
          <MenuItem value={"course_name"}>Title</MenuItem>
          <MenuItem value={"course_number"}>Course</MenuItem>
        </StyledSelect>
      </FormControl>
      </ThemeProvider>
    </div>
  )

}
