import React, { useState, useEffect, useContext } from "react";
import logo from "./logo.svg";
import "./App.css";
import MainApp from "./MainApp";
import Header from "./main/Header";
import Footer from "./main/Footer";
import { BrowserRouter } from "react-router-dom";
import Weblogin from "./main/Weblogin";

function App() {
  const [auth, setAuth] = useState(null);

  return (
    <Weblogin login={(auth) => setAuth(auth)}>
      <BrowserRouter>
        <div className="App">
          <script src="https://player.live-video.net/1.2.0/amazon-ivs-player.min.js"></script>
          <Header auth={auth} />
          <MainApp auth={auth} />
          <Footer />
        </div>
      </BrowserRouter>
    </Weblogin>
  );
}

export default App;
