import React, { useState, useEffect, useContext } from "react";
import {ClassCard, SearchBar, SortBy, ClassList,ViewToggle} from "stories";
import axios from "axios";
import "./StreamList.css"
import moment from "moment";

import Channel from "./Channel";

var heldChannels = [];
var heldFilter = "";

export default function StreamList(props) {

  const [allChannels,setAllChannels] = useState([]);
  const [channels,setChannels] = useState([]);
  const [lastUpd, setLastUpd] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [view, setView] = useState("card");

  var channelInterval = null;
  const MATCH_AGAINST = ["course_name","course_number","prof"];
  const INTERVAL_LEN = 60000;


  useEffect(() => {
    fetchChannels(true);
    setupInterval();

    return () => {
      try {
        clearInterval(channelInterval)
      } catch(e) {}
    }

  },[])


  const fetchChannels = async (firstLoad) => {

    let env = "prod"

    if( window.location.href.indexOf("test=true") > -1 ) {
      env = "test"
    } else if( window.location.href.indexOf("dev=true") > -1 ) {
      env = "dev"
    }

    let getChannelsUrl = "https://nl0cwt8hhg.execute-api.us-west-2.amazonaws.com/"+env+"/channels"

    let resp = await axios.get(getChannelsUrl);

    setLastUpd(moment().format("MM/DD/YYYY h:mm:ss a"))

    resp.data = getNextClassTimes(resp.data);

    setAllChannels(resp.data);

    if( firstLoad ) {
      heldChannels = resp.data;
      setChannels(resp.data);
    } else {
      let chDupe = [...heldChannels];

      for( var j = 0; j < chDupe.length; ++j ) {
        for( var i = 0; i < resp.data.length; ++i ) {
          if( resp.data[i].id === chDupe[j].id ) {
            chDupe[j] = resp.data[i];
          }
        }
      }

      heldChannels = chDupe;
      setChannels(chDupe);
    }

  }

  const getNextClassTimes = (arr) => {

    let unix_currTime = moment().unix();
    let startWeek = moment().add(0,"weeks").startOf('week').unix();

    let jsStartWeekDate = new Date(startWeek*1000);

    for( var i = 0; i < arr.length; ++i ) {

      let curr = arr[i].classInfo.schedule;
      let date = new Date(curr.baseDate*1000);

      let nct = null;
      let weekDiff = 0;

      if( curr.type === "generated" ) {
        weekDiff = moment(jsStartWeekDate).diff(date, 'weeks');
      }

      if( curr.type )
        nct = getNextClassDate(curr,weekDiff,unix_currTime);

      if( !nct && curr.type === "generated" ) {
        nct = getNextClassDate(curr,weekDiff+1,unix_currTime);
      }

      arr[i].nct = nct;

    }

    return arr;

  }

  const getNextClassDate = (curr,weekDiff,unix_currTime) => {

    let ans = null;
    let currDiff = 1000000000000;

    for( var j = 0; j < curr.schedule.length; ++j ) {
      let schDay = curr.schedule[j];

      let nStart = moment(new Date(schDay.start)*1000).add(weekDiff,"weeks").unix();
      let nEnd = moment(new Date(schDay.end)*1000).add(weekDiff,"weeks").unix();

      if( unix_currTime < nEnd ) {
        if( unix_currTime >= nStart && unix_currTime <= nEnd ) { //During class
          ans = {
            start: nStart,
            end: nEnd
          }
        } else if ( unix_currTime < nStart ) {
          let x_diff = nStart - unix_currTime;

          if( x_diff < currDiff ) {
            currDiff = x_diff;
            ans = {
              start: nStart,
              end: nEnd
            }
          }
        }
      }
    }

    return ans;

  }

  const viewStream = (channel) => {
    props.playStream(channel);
  }

  const setupInterval = () => {
    channelInterval = setInterval(() => {
      fetchChannels();
    },INTERVAL_LEN)
  }

  const refreshList = () => {
    try {
      clearInterval(setupInterval);
    } catch(e) {}

    fetchChannels();
    setupInterval();

  }

  const strCon = (str,con) => {
    return str.toLowerCase().indexOf(con.toLowerCase()) > -1
  }

  useEffect(() => {
    searchForTerm(searchTerm);
  },[searchTerm])

  const acceptSearch = (term) => {
    heldFilter = term;
    setSearchTerm(term);
  }

  const searchForTerm = (term) => {


    let channelsToShow = null;

    if( term !== "" ) {
      let newChannels = [];
      for( var i = 0; i < allChannels.length; ++i ) {
        let info = allChannels[i].classInfo;

        let isMatch = false;

        for( var j = 0;j < MATCH_AGAINST.length; ++j ) {
          if( strCon(info[MATCH_AGAINST[j]],term) ) {
            isMatch = true;
            break;
          }
        }

        if( isMatch ) {
          newChannels.push(allChannels[i])
        }
      }

      channelsToShow = newChannels;

    } else {
      channelsToShow = allChannels
    }

    setChannels(channelsToShow);
    heldChannels = channelsToShow

  }

  const sortByOnline = () => {

    let types = {};

    let d_channels = [...heldChannels];

    d_channels.sort((a,b) => {
      if(a.status < b.status) { return 1; }
      if(a.status > b.status) { return -1; }
      return 0;
    })

    heldChannels = d_channels;
    setChannels(d_channels);

    let d_channels1 = [...allChannels];

    d_channels1.sort((a,b) => {
      if(a.status < b.status) { return 1; }
      if(a.status > b.status) { return -1; }
      return 0;
    })

    setAllChannels(d_channels1);

  }

  const sortByName = (nameType) => {

    let types = {};

    let d_channels = [...heldChannels];

    d_channels.sort((a,b) => {
      if(a.classInfo[nameType] < b.classInfo[nameType]) { return -1; }
      if(a.classInfo[nameType] > b.classInfo[nameType]) { return 1; }
      return 0;
    })

    heldChannels = d_channels;
    setChannels(d_channels);

    let d_channels1 = [...allChannels];

    d_channels1.sort((a,b) => {
      if(a.classInfo[nameType] < b.classInfo[nameType]) { return -1; }
      if(a.classInfo[nameType] > b.classInfo[nameType]) { return 1; }
      return 0;
    })

    setAllChannels(d_channels1);

  }

  const sortByDate = () => {

    let types = {};

    let d_channels = [...heldChannels];

    d_channels.sort((a,b) => {

      if( !a.nct ) a.nct = {start: 999999999999, end: 999999999999}
      if( !b.nct ) b.nct = {start: 999999999999, end: 999999999999}

      return a.nct.end - b.nct.end;

    })

    heldChannels = d_channels;
    setChannels(d_channels);

    let d_channels1 = [...allChannels];

    d_channels1.sort((a,b) => {
      if( !a.nct ) a.nct = {start: 999999999999, end: 999999999999}
      if( !b.nct ) b.nct = {start: 999999999999, end: 999999999999}

      return a.nct.end - b.nct.end;
    })

    setAllChannels(d_channels1);

  }

  const sortByChange = (e) => {
    switch( e ) {
      case "online":
        sortByOnline();
        break;
      case "date":
        sortByDate();
        break;
      case "course_number":
      case "course_name":
        sortByName(e);
        break;
    }
  }

  return (
    <div className="channelHolder">
      <div className="channelList">

      <div className="courseListHeader">
        <div className="coursesTitle">
          Courses
        </div>
        <div className="courseSearch">
          <SearchBar searchForTerm={searchForTerm} />
        </div>
      </div>

      <div className="courseListSort">
        <SortBy sortByChange={sortByChange} />
        <ViewToggle toggleView={((v)=>setView(v))}/>
      </div>

      {
        view === "card" ? (
          <div className="channelListing">
            {heldChannels.map((item,index) => {
              return (<ClassCard key={item.id+"_channel"} info={item} viewStream={viewStream} auth={props.auth}/> )
            }) }
          </div>
        ) : (
          <div className="channelListing">
            <ClassList courses={heldChannels} viewStream={viewStream} />
          </div>
        )

      }




        <div className="noChannels">
          {
            channels.length === 0 ? (
              <div>No Courses Found</div>
            ) : null
          }
        </div>

      </div>
      <div className="lastUpd">
        <div>
          Last Updated: {lastUpd ? lastUpd : "Never"}
        </div>
      </div>
    </div>
  )

}
