import React, { useState, useEffect } from "react";
import { AUTH_ENDPOINT, CLIENT_ID2, REDIRECT_URI2 } from "../constants";

function Header(props) {
  useEffect(() => {
    let userInfoString = localStorage.getItem("userinfo");
    if (userInfoString) {
      let userInfoJSON = JSON.parse(userInfoString);
      setEmailId(userInfoJSON.email);
    }
  }, []);

  const [emailId, setEmailId] = useState("");

  const signOut = () => {
    localStorage.removeItem("userinfo");
    localStorage.removeItem("usertokens");
    window.location.href =
      //    AUTH_ENDPOINT +
      //    "/login?client_id=" +
      //    CLIENT_ID2[process.env.NODE_ENV] +
      //  "&response_type=code&redirect_uri=" +
      REDIRECT_URI2[process.env.NODE_ENV];
  };

  const toggleASU = function (id) {
    if (!id) {
      id = "asu_mobile_menu";
    }
    var el = document.getElementById(id);
    if (id === "asu_mobile_menu") {
      var m = document.getElementById("asu_mobile_button");
      //var blackOut = document.getElementById("blackOut");
    }
    if (id === "main-search") {
      var a = document.getElementById("search_new");
      var b = document.getElementById("asu_search_box");
      a.classList.toggle("clicked");
      if (a.classList.contains("clicked")) {
        b.focus();
      } else {
        b.blur();
      }
    }
    if (typeof el != "undefined" && el != null) {
      if (el.classList.contains("closed")) {
        if (m) {
          m.classList.remove("f-navicon");
          m.classList.add("f-times");
          //blackOut.style.display = "inherit";
          var body = document.body,
            html = document.documentElement;
          var height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
          );
          //blackOut.style.height = height + "px";
        }
        el.classList.remove("closed");
        el.classList.add("opened");
      } else {
        if (m) {
          m.classList.remove("f-times");
          m.classList.add("f-navicon");
          //blackOut.style.display = "none";
          //blackOut.style.height = "0px";
        }
        closeMenuItems();
        el.classList.remove("opened");
        el.classList.add("closed");
      }
    }
  };
  /*
   * *****
   * - CLOSE ALL OPEN MENU ITEMS
   * *****
   */
  //private
  function closeMenuItems() {
    var a = [];
    a = document.getElementsByClassName("f-sort-up");
    closeTheStuff(a);
  }

  function closeTheStuff(a) {
    for (var i = 0, len = a.length; i < len; i++) {
      if (a[i] && a[i].parentNode) {
        var x = a[i].parentNode.nextSibling;
        if (x != null && x.classList.contains("opened")) {
          x.classList.remove("opened");
          x.classList.add("closed");
        }
        a[i].classList.add("f-sort-down");
        a[i].classList.remove("f-sort-up");
      }
    }
  }

  return (
    <div>
      <div className="col-md-12">
        <div id="main-search" className="main-search closed">
          <form
            target="_top"
            action="https://search.asu.edu/search"
            method="get"
            name="gs"
            role="search"
          >
            <label htmlFor="asu_search_box">Search</label>
            <input name="site" type="hidden" value="default_collection" />
            <input name="sort" type="hidden" value="date:D:L:d1" />
            <input name="output" type="hidden" value="xml_no_dtd" />
            <input name="proxystylesheet" type="hidden" value="asu_frontend" />
            <input name="ie" type="hidden" value="UTF-8" />
            <input name="oe" type="hidden" value="UTF-8" />
            <input name="client" type="hidden" value="asu_frontend" />
            <input
              name="q"
              className="asu_search_box"
              id="asu_search_box"
              placeholder="Search"
              autoComplete="off"
              type="text"
            />
            <input className="asu_search_button" type="submit" value="Search" />
          </form>
        </div>
        <div id="asu-report-accessiblity" className="accessibility-hide">
          <a href="https://www.asu.edu/accessibility/" className="sr-only">
            Report an accessibility problem
          </a>
        </div>
        <div id="asu_hdr" className="asu_hdr_white">
          <div id="asu_mobile_hdr">
            <div id="asu_logo">
              <a href="//www.asu.edu/" target="_top">
                <img
                  alt="A S U"
                  src="//www.asu.edu/asuthemes/4.7/assets/full_logo.png"
                />
              </a>
            </div>
            <div
              className="asuhide f-navicon"
              id="asu_mobile_button"
              onClick={() => toggleASU()}
              role="button"
            >
              <a onClick={() => toggleASU()}>Menu</a>
            </div>
            <div
              className="f-search"
              id="search_new"
              onClick={() => {
                toggleASU("main-search");
              }}
            ></div>
          </div>
          <div className="closed" id="asu_mobile_menu">
            <div id="asu_nav_wrapper">
              {props.auth === "authenticated" ? (
                <div>
                  <span className="hidden">Sign In / Sign Out</span>
                  <ul id="asu_login_module">
                    <li>{emailId}</li>
                    <li className="end" id="asu_hdr_ssi" onClick={signOut}>
                      <a tabIndex="0">Sign Out</a>
                    </li>
                  </ul>{" "}
                </div>
              ) : null}
              <div
                id="asu_nav_menu"
                role="navigation"
                aria-labelledby="asuNavLabel"
              >
                <span className="sr-only" id="asuNavLabel">
                  Navigation for Entire University
                </span>
                <div id="asu_universal_nav">
                  <ul>
                    <li className="parent">
                      <a href="//www.asu.edu/" target="_top">
                        ASU Home
                      </a>
                      <ul>
                        <li>
                          <a
                            className="first"
                            href="//www.asu.edu/?feature=newsevents"
                            target="_top"
                            title="News and Events"
                          >
                            News/Events
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/?feature=academics"
                            target="_top"
                            title="Academics"
                          >
                            Academics
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/?feature=research"
                            target="_top"
                            title="Research"
                          >
                            Research
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/?feature=athletics"
                            target="_top"
                            title="Athletics"
                          >
                            Athletics
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/?feature=alumni"
                            target="_top"
                            title="Alumni"
                          >
                            Alumni
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/?feature=giving"
                            target="_top"
                            title="Giving"
                          >
                            Giving
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/?feature=president"
                            target="_top"
                            title="President"
                          >
                            President
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.asu.edu/about"
                            target="_top"
                            title="About ASU"
                          >
                            About ASU
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="//my.asu.edu/" target="_top">
                        My ASU
                      </a>
                    </li>
                    <li className="parent">
                      <a href="//www.asu.edu/colleges/" target="_top">
                        Colleges and Schools
                      </a>
                      <ul>
                        <li>
                          <a
                            className="first"
                            href="//artsandsciences.asu.edu/"
                            target="_top"
                            title="Arts and Sciences website"
                          >
                            Arts and Sciences
                          </a>
                        </li>
                        <li>
                          <a
                            href="//wpcarey.asu.edu/"
                            target="_top"
                            title="W. P. Carey School of Business Web and Morrison School of Agribusiness website"
                          >
                            Business
                          </a>
                        </li>
                        <li>
                          <a
                            href="//herbergerinstitute.asu.edu"
                            target="_top"
                            title="Herberger Institute for Design and the Arts website"
                          >
                            Design and the Arts
                          </a>
                        </li>
                        <li>
                          <a
                            href="//education.asu.edu/"
                            target="_top"
                            title="Mary Lou Fulton Teachers College website"
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <a
                            href="//engineering.asu.edu/"
                            target="_top"
                            title="Engineering website"
                          >
                            Engineering
                          </a>
                        </li>
                        <li>
                          <a
                            href="//sfis.asu.edu/"
                            target="_top"
                            title="Future of Innovation in Society website"
                          >
                            Future of Innovation in Society
                          </a>
                        </li>
                        <li>
                          <a
                            href="//graduate.asu.edu"
                            target="_top"
                            title="Graduate College website"
                          >
                            Graduate
                          </a>
                        </li>
                        <li>
                          <a
                            href="//chs.asu.edu/"
                            target="_top"
                            title="Health Solutions website"
                          >
                            Health Solutions
                          </a>
                        </li>
                        <li>
                          <a
                            href="//honors.asu.edu/"
                            target="_top"
                            title="Barrett, The Honors College website"
                          >
                            Honors
                          </a>
                        </li>
                        <li>
                          <a
                            href="//cronkite.asu.edu"
                            target="_top"
                            title="Walter Cronkite School of Journalism and Mass Communication website"
                          >
                            Journalism
                          </a>
                        </li>
                        <li>
                          <a
                            href="//www.law.asu.edu/"
                            target="_top"
                            title="Sandra Day O' Connor College of Law website"
                          >
                            Law
                          </a>
                        </li>
                        <li>
                          <a
                            href="//nursingandhealth.asu.edu/"
                            target="_top"
                            title="College of Nursing and Health Innovation website"
                          >
                            Nursing and Health Innovation
                          </a>
                        </li>
                        <li>
                          <a
                            href="//copp.asu.edu"
                            target="_top"
                            title="College of Public Programs website"
                          >
                            Public Service and Community Solutions
                          </a>
                        </li>
                        <li>
                          <a
                            href="//schoolofsustainability.asu.edu"
                            target="_top"
                            title="School of Sustainability website"
                          >
                            Sustainability
                          </a>
                        </li>
                        <li>
                          <a
                            href="//uc.asu.edu/"
                            target="_top"
                            title="University College website"
                          >
                            University College
                          </a>
                        </li>
                        <li>
                          <a
                            target="_top"
                            href="https://www.thunderbird.edu/"
                            title="Thunderbird School of Global Management website"
                          >
                            Thunderbird School of Global Management
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="parent">
                      <a href="//www.asu.edu/map/" target="_top">
                        Map and Locations
                      </a>
                      <ul>
                        <li>
                          <a
                            className="asu-border first"
                            href="//www.asu.edu/map/"
                            target="_top"
                          >
                            Map
                          </a>
                        </li>
                        <li>
                          <a
                            href="//campus.asu.edu/tempe/"
                            target="_top"
                            title="Tempe campus"
                          >
                            Tempe
                          </a>
                        </li>
                        <li>
                          <a
                            href="//campus.asu.edu/west/"
                            target="_top"
                            title="West campus"
                          >
                            West
                          </a>
                        </li>
                        <li>
                          <a
                            href="//campus.asu.edu/polytechnic/"
                            target="_top"
                            title="Polytechnic campus"
                          >
                            Polytechnic
                          </a>
                        </li>
                        <li>
                          <a
                            href="//campus.asu.edu/downtown/"
                            target="_top"
                            title="Downtown Phoenix campus"
                          >
                            Downtown Phoenix
                          </a>
                        </li>
                        <li>
                          <a
                            href="//asuonline.asu.edu/"
                            target="_top"
                            title="Online and Extended campus"
                          >
                            Online and Extended
                          </a>
                        </li>
                        <li>
                          <a
                            className="asu-border"
                            href="//havasu.asu.edu/"
                            target="_top"
                          >
                            Lake Havasu
                          </a>
                        </li>
                        <li>
                          <a href="https://corporate.asu.edu/" target="_top">
                            Corporate Engagement and Strategic Partnerships
                          </a>
                        </li>
                        <li>
                          <a href="//asuresearchpark.com/" target="_top">
                            Research Park
                          </a>
                        </li>
                        <li>
                          <a href="//washingtoncenter.asu.edu/" target="_top">
                            Washington D.C.
                          </a>
                        </li>
                        <li>
                          <a
                            href="//wpcarey.asu.edu/mba/china-program/english/"
                            target="_top"
                          >
                            China
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href="//isearch.asu.edu/"
                        target="_top"
                        title="Directory, Index and other info"
                      >
                        Directory
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="asu_universal_nav_new">
                  <ul>
                    <li className="tlb site_title">
                      <span>ASU Sync Showcase</span>
                    </li>
                    {props.auth === "authenticated" ? (
                      <li class="tlb">
                        <div class="text">
                          <a tabIndex="0" onClick={signOut}>
                            {emailId} Sign Out
                          </a>
                        </div>
                        <div id="f-user" class="icn f-user"></div>
                      </li>
                    ) : null}
                    <li className="tlb">
                      <div className="text">
                        <a>ASU Info</a>
                      </div>
                      <div className="icn f-sort-down"></div>
                    </li>
                    <li className="tlb">
                      <ul>
                        <li className="cb">
                          <a href="//www.asu.edu/" target="_top">
                            ASU Home
                          </a>
                          <div className="icn2 f-sort-down"></div>
                        </li>
                        <li className="clb closed parent">
                          <ul>
                            <li className="ccb">
                              <a
                                className="first"
                                href="//www.asu.edu/?feature=newsevents"
                                target="_top"
                                title="News and Events"
                              >
                                News/Events
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/?feature=academics"
                                target="_top"
                                title="Academics"
                              >
                                Academics
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/?feature=research"
                                target="_top"
                                title="Research"
                              >
                                Research
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/?feature=athletics"
                                target="_top"
                                title="Athletics"
                              >
                                Athletics
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/?feature=alumni"
                                target="_top"
                                title="Alumni"
                              >
                                Alumni
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/?feature=giving"
                                target="_top"
                                title="Giving"
                              >
                                Giving
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/?feature=president"
                                target="_top"
                                title="President"
                              >
                                President
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.asu.edu/about"
                                target="_top"
                                title="About ASU"
                              >
                                About ASU
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="cb">
                          <a href="//my.asu.edu/" target="_top">
                            My ASU
                          </a>
                        </li>
                        <li className="cb">
                          <a href="//www.asu.edu/colleges/" target="_top">
                            Colleges and Schools
                          </a>
                          <div className="icn2 f-sort-down"></div>
                        </li>
                        <li className="clb closed parent">
                          <ul>
                            <li className="ccb">
                              <a
                                className="first"
                                href="//artsandsciences.asu.edu/"
                                target="_top"
                                title="Arts and Sciences website"
                              >
                                Arts and Sciences
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//wpcarey.asu.edu/"
                                target="_top"
                                title="W. P. Carey School of Business Web and Morrison School of Agribusiness website"
                              >
                                Business
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//herbergerinstitute.asu.edu"
                                target="_top"
                                title="Herberger Institute for Design and the Arts website"
                              >
                                Design and the Arts
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//education.asu.edu/"
                                target="_top"
                                title="Mary Lou Fulton Teachers College website"
                              >
                                Education
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//engineering.asu.edu/"
                                target="_top"
                                title="Engineering website"
                              >
                                Engineering
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//sfis.asu.edu/"
                                target="_top"
                                title="Future of Innovation in Society website"
                              >
                                Future of Innovation in Society
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//graduate.asu.edu"
                                target="_top"
                                title="Graduate College website"
                              >
                                Graduate
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//chs.asu.edu/"
                                target="_top"
                                title="Health Solutions website"
                              >
                                Health Solutions
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//honors.asu.edu/"
                                target="_top"
                                title="Barrett, The Honors College website"
                              >
                                Honors
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//cronkite.asu.edu"
                                target="_top"
                                title="Walter Cronkite School of Journalism and Mass Communication website"
                              >
                                Journalism
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//www.law.asu.edu/"
                                target="_top"
                                title="Sandra Day O' Connor College of Law website"
                              >
                                Law
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//nursingandhealth.asu.edu/"
                                target="_top"
                                title="College of Nursing and Health Innovation website"
                              >
                                Nursing and Health Innovation
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//copp.asu.edu"
                                target="_top"
                                title="College of Public Programs website"
                              >
                                Public Service and Community Solutions
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//schoolofsustainability.asu.edu"
                                target="_top"
                                title="School of Sustainability website"
                              >
                                Sustainability
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//uc.asu.edu/"
                                target="_top"
                                title="University College website"
                              >
                                University College
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="https://www.thunderbird.edu/"
                                target="_top"
                                title="Thunderbird School of Global Management website"
                              >
                                Thunderbird School of Global Management
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="cb">
                          <a href="//www.asu.edu/map/" target="_top">
                            Map and Locations
                          </a>
                          <div className="icn2 f-sort-down"></div>
                        </li>
                        <li className="clb closed parent">
                          <ul>
                            <li className="ccb">
                              <a
                                className="asu-border first"
                                href="//www.asu.edu/map/"
                                target="_top"
                              >
                                Map
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//campus.asu.edu/tempe/"
                                target="_top"
                                title="Tempe campus"
                              >
                                Tempe
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//campus.asu.edu/west/"
                                target="_top"
                                title="West campus"
                              >
                                West
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//campus.asu.edu/polytechnic/"
                                target="_top"
                                title="Polytechnic campus"
                              >
                                Polytechnic
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//campus.asu.edu/downtown/"
                                target="_top"
                                title="Downtown Phoenix campus"
                              >
                                Downtown Phoenix
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//asuonline.asu.edu/"
                                target="_top"
                                title="Online and Extended campus"
                              >
                                Online and Extended
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                className="asu-border"
                                href="//havasu.asu.edu/"
                                target="_top"
                              >
                                Lake Havasu
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="https://corporate.asu.edu/"
                                target="_top"
                              >
                                Corporate Engagement and Strategic Partnerships
                              </a>
                            </li>
                            <li className="ccb">
                              <a href="//asuresearchpark.com/" target="_top">
                                Research Park
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//washingtoncenter.asu.edu/"
                                target="_top"
                              >
                                Washington D.C.
                              </a>
                            </li>
                            <li className="ccb">
                              <a
                                href="//wpcarey.asu.edu/mba/china-program/english/"
                                target="_top"
                              >
                                China
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="cb">
                          <a
                            href="//isearch.asu.edu/"
                            target="_top"
                            title="Directory, Index and other info"
                          >
                            Directory
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="asu_search">
              <div
                id="asu_search_module"
                className="asu_search_module"
                name="asu_search_module"
              >
                <form
                  target="_top"
                  action="https://search.asu.edu/search"
                  method="get"
                  name="gs"
                  role="search"
                >
                  <label htmlFor="masu_search_box">Search</label>
                  <input name="site" type="hidden" value="default_collection" />
                  <input name="sort" type="hidden" value="date:D:L:d1" />
                  <input name="output" type="hidden" value="xml_no_dtd" />
                  <input
                    name="proxystylesheet"
                    type="hidden"
                    value="asu_frontend"
                  />
                  <input name="ie" type="hidden" value="UTF-8" />
                  <input name="oe" type="hidden" value="UTF-8" />
                  <input name="client" type="hidden" value="asu_frontend" />
                  <input
                    name="q"
                    className="asu_search_box"
                    id="masu_search_box"
                    placeholder="Search"
                    autoComplete="off"
                    type="text"
                  />
                  <input
                    className="asu_search_button"
                    type="submit"
                    value="Search"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="header__sitename" id="asu_hdr_title_gen">
          <div>
            <a title="Home" rel="home" href="/" id="asu_hdr_title">
              <span className="asu-site-title">ASU Sync Showcase</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
