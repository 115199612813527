function Footer() {
  return (
    <div id="innovation-footer">
      <div id="innovation-bar">
        <div class="innovation-top">
          <div class="innovation-status">
            <a href="https://www.asu.edu/rankings">
              <span>ASU is #1 in the U.S. for Innovation</span>
            </a>
          </div>
          <div class="innovation-hidden">
            <a href="https://www.asu.edu/rankings">
              <img
                src="https://webapp4.asu.edu/myasu/myasuthemes/4.8/assets/ASU-number-1-innovation-best-colleges.png"
                alt="Best Colleges U.S. News Most Innovative 2020"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-menu">
        <ul class="default">
          <li>
            <a href="https://www.asu.edu/copyright/">Copyright and Trademark</a>
          </li>
          <li>
            <a href="https://www.asu.edu/accessibility/">Accessibility</a>
          </li>
          <li>
            <a href="https://www.asu.edu/privacy/">Privacy</a>
          </li>
          <li>
            <a href="https://www.asu.edu/tou/">Terms of Use</a>
          </li>
          <li>
            <a href="https://www.asu.edu/asujobs">Jobs</a>
          </li>
          <li>
            <a href="https://www.asu.edu/emergency/">Emergency</a>
          </li>
          <li class="no-border">
            <a href="https://www.asu.edu/contactasu/">Contact ASU</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
