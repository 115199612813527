import { useEffect } from 'react';

const useCssLink = (url,id) => {
  useEffect(() => {

    let link = null;

    if( !document.getElementById(id) ) {

      link = document.createElement('link');

      link.id = id;
      link.src = url;
      link.async = true;

      document.body.appendChild(link);
    }

    return () => {
      try {
        document.body.removeChild(link);
      } catch(e) {}
    }
  }, [url]);
};

export default useCssLink;
