import React, { useEffect, useState, useContext } from "react";
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import "./ViewToggle.css";

export default function SearchBarX(props) {

  const [view, setView] = useState("card");

  const changeView = (x) => {
    setView(x);
    props.toggleView(x);
  }

  return (
    <div className="viewTypeHolder">
      <div className={"buttonOption "+(view==="card" ? "viewSelected" : "")} onClick={()=>{changeView("card")}}>
        <ViewModuleIcon style={{height: "30px",width:"30px"}}/>
      </div>
      <div className={"buttonOption "+(view==="list" ? "viewSelected" : "")} onClick={()=>{changeView("list")}}>
        <ViewListIcon style={{height: "30px",width:"30px"}}/>
      </div>
    </div>
  )

}
