import React from "react";
import HeroHeader from "./HeroHeader/HeroHeader";
import Basic from "./Basic/Basic";

export default function Text(props) {

  if( props.type === "hero" ) {
    return <HeroHeader {...props} />;
  } else {
    return <Basic {...props} />;
  }

}
