import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import moment from "moment";
import "./ClassList.css";

const ONLINE_FLAG = "ONLINE";
const DISABLED_FLAG = "DISABLED";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ClassList(props) {

  const classes = useStyles();

  const selectCourse = (i) => {
    props.viewStream(i)
  }

  const watchNowLabel = (item) => {
    return (
      <div className="tableLabelBase watchNowLabel" onClick={() => selectCourse(item)}>
        WATCH NOW
        <PlayArrowIcon style={{ width: "20px", height: "16px" }}/>
      </div>
    )
  }

  const disabledLabel = (item) => {
    return (
      <div className="tableLabelBase disabledLabel">
        DISABLED
      </div>
    )
  }

  const emptyLabel = (item) => {
    return (
      <div className="tableLabelBase emptyButton">
        {" "}
      </div>
    )
  }

  const getButton = (r) => {
    if( r.status === ONLINE_FLAG ) {
      return watchNowLabel(r)
    } else if ( r.status === DISABLED_FLAG ) {
      return disabledLabel(r)
    } else {
      return emptyLabel(r)
    }
  }

  const getNextClassTime = (t) => {

    let time = "Class time unknown"

    if( t ) {
      let s_date = new Date(t.start*1000);
      let e_date = new Date(t.end*1000);

      time = moment(s_date).format("dddd, MMMM D h:mma") + " - " + moment(e_date).format("h:mma");
    }

    return <div>{time}</div>

  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>COURSE</TableCell>
            <TableCell>TITLE</TableCell>
            <TableCell>INSTRUCTOR</TableCell>
            <TableCell>NEXT MEET TIME</TableCell>
            <TableCell style={{textAlign: "center",width: "150px"}}>STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.courses.map((row) => {

            let item = row.classInfo;
            let classTimes = item.times.split("|")

            return (
              <TableRow key={row.id+"_tablerow"}>
                <TableCell style={{width: "100px"}}>
                  {item.course_number}
                </TableCell>
                <TableCell className="redUnderline">{item.course_name}</TableCell>
                <TableCell className="redUnderline">{item.prof}</TableCell>
                <TableCell>
                  {getNextClassTime(row.nct)}
                </TableCell>
                <TableCell style={{width: "150px"}}>
                  <div style={{display:"flex",justifyContent: "center"}}>
                    {getButton(row)}
                  </div>

                </TableCell>
              </TableRow>
            )

          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

}
