import { useEffect } from 'react';

const useScript = (url,id) => {
  useEffect(() => {

    let script = null;

    if( !document.getElementById(id) ) {

      script = document.createElement('script');

      script.id = id;
      script.src = url;
      script.async = true;

      document.body.appendChild(script);
    }

    return () => {
      try {
        document.body.removeChild(script);
      } catch(e) {}
    }
  }, [url]);
};

export default useScript;
