export const AUTH_ENDPOINT = "https://signin.asu.edu";
export const CLIENT_ID2 = {
  development: "7mned1kctld70mjejgtv503dbq",
  production: "5ika2ma3443f0rsfe83297gqcc"
};
export const REDIRECT_URI2 = {
  development: "http://localhost:3000",
  production: "https://syncshowcase.asu.edu/"
};
export const PROVIDERS = {
  default: "/login?client_id=",
  asu: "/oauth2/authorize?identity_provider=asurite-shibboleth&client_id=",
  apple: "/oauth2/authorize?identity_provider=SignInWithApple&client_id=",
  google: "/oauth2/authorize?identity_provider=Google&client_id=",
  facebook: "/oauth2/authorize?identity_provider=Facebook&client_id="
}
export const CLIENT_ID = "7mned1kctld70mjejgtv503dbq";
export const REDIRECT_URI = "http://localhost:3000";
